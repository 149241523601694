@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
*{box-sizing:border-box;margin:0;padding:0}html{scroll-behavior:smooth}body{margin:0;font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

nav{position:fixed;top:0;width:100%;background-color:rgba(255,255,255,0.8);padding:1em 2em;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}nav a.name{font-weight:400;text-decoration:none;color:#000}nav .nav-content{display:-webkit-flex;display:flex;width:100%;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}ul{display:-webkit-flex;display:flex;list-style-type:none}ul li{padding:0em 1em}li a{text-decoration:none;font-weight:400;transition:0.5s;color:rgba(0,0,0,0.6)}li a:hover{color:#000}.burger{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:space-between;justify-content:space-between;height:2.5vh;display:none}.burger div{width:20px;height:2px;background-color:#000}@media screen and (max-width: 480px){nav{-webkit-align-items:flex-start;align-items:flex-start}.burger{display:-webkit-flex;display:flex}nav .nav-content{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;width:unset}ul{display:none}ul.show{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}ul.show li{padding:1em 0em}}

.About{background-color:#eee}h1{text-align:center;padding-top:1em;font-weight:200}.about-content{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;width:80%;margin:0em auto;padding:1em}.about-content .profileAboutDiv{text-align:justify;padding:0em 1em;line-height:2em;width:40%}.profileDiv img{padding:0em 1em;width:250px;border-radius:50%}@media screen and (max-width: 768px){.about-content .profileAboutDiv{width:100%}.profileDiv img{display:none}}

.Project{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-flex-direction:column;flex-direction:column}.project-card-container{display:grid;max-width:80%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-flex-wrap:wrap;flex-wrap:wrap}.project-card{margin:1em;padding:1em;border-radius:0.2em;box-shadow:rgba(0,0,0,0.15) 1.95px 1.95px 2.6px;width:400px}.project-name{font-weight:300}.project-description{margin:1em 0em}.links{margin-top:1em;display:-webkit-flex;display:flex;-webkit-justify-content:space-evenly;justify-content:space-evenly;-webkit-align-items:center;align-items:center}

.contact-content{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding:1em}.social{margin:1em 0em}.social a{color:black}a.mail{text-decoration:none}

@-webkit-keyframes change{0%{background-position:0%}50%{background-position:100% 100%}100%{background-position:0%}}@keyframes change{0%{background-position:0%}50%{background-position:100% 100%}100%{background-position:0%}}@-webkit-keyframes float{0%{-webkit-transform:translatey(0px);transform:translatey(0px)}50%{-webkit-transform:translatey(-10px);transform:translatey(-10px)}100%{-webkit-transform:translatey(0px);transform:translatey(0px)}}@keyframes float{0%{-webkit-transform:translatey(0px);transform:translatey(0px)}50%{-webkit-transform:translatey(-10px);transform:translatey(-10px)}100%{-webkit-transform:translatey(0px);transform:translatey(0px)}}.Hero{background:linear-gradient(-45deg, #c66, #66c, #6cc, #c6c) 0% 0%/1200% 1200%;min-height:100vh;-webkit-animation:change linear 60s infinite;animation:change linear 60s infinite;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.Hero h1{font-weight:200;font-size:5em;text-align:center;color:#fff;-webkit-animation:float 6s ease-out infinite;animation:float 6s ease-out infinite}.Hero .tags{font-weight:200;font-size:1.5em;color:#fff;margin:1em 0em;text-align:center}a.cta{color:#fff;text-decoration:none;background:transparent;border:none;font-size:1.5em;padding:0.25em 0.5em;border-radius:0.2em;transition:0.5s;font-weight:200;border:1px solid #fff}a.cta:hover{color:#000;background:#fff}@media screen and (max-width: 768px){.Hero h1{font-size:4em}.Hero .tags{font-size:1em}}@media screen and (max-width: 480px){.Hero h1{font-size:3em}.Hero .tags{font-size:1em}}

