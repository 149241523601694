@keyframes change {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0%;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
